import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import chunk from "lodash/chunk"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import MetaData from "../components/meta"
import Layout from "../components/Layout"
import ResourceCard from "../components/ResourceCard"
import getRedirectUrl from "../utils/getRedirectUrl"
import SecondaryNav from "../components/SecondaryNav"
import { Grid, Box } from "../elements"

// Import styles
import "../styles/resources.scss"

const ResourcesPage = ({ data, location }) => {
  const posts = data.posts.edges
  const featured_resource = data.featured_resource
  const featured_resource_external_link = getRedirectUrl(
    featured_resource?.codeinjection_head
  )
  const resourcesPage = data.resources_page
  const post_chunks = chunk(posts, 15)
  const [page, setPage] = useState(0)
  const [renderedPosts, setRenderedPosts] = useState(
    posts.length > 0 ? post_chunks[0] : []
  )
  const [loading, setLoader] = useState(false)

  const loadPosts = () => {
    setLoader(true)
    setRenderedPosts(renderedPosts.concat(post_chunks[page + 1]))
    setPage(page + 1)
    setTimeout(() => {
      setLoader(false)
    }, 100)
  }

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.currentPage,
        }}
      />

      <div className="resources">
        {/* Featured Resource */}
        {featured_resource && (
          <div
            className={`resources__featured resources__featured--${featured_resource.tags[1].slug}`}
          >
            <div className="overlay">
              <div className="wrapper-1200">
                <div className="content">
                  <div className="resource-card__tag">
                    <Link to={`/resources/${featured_resource.tags[1].slug}/`}>
                      {featured_resource.tags[1].name}
                    </Link>
                  </div>

                  {featured_resource_external_link && (
                    <a href={featured_resource_external_link}>
                      <h2 className="resource-card__title">
                        {featured_resource.title}
                      </h2>
                    </a>
                  )}
                  {!featured_resource_external_link && (
                    <Link to={`/resources/${featured_resource.slug}/`}>
                      <h2 className="resource-card__title">
                        {featured_resource.title}
                      </h2>
                    </Link>
                  )}

                  {featured_resource_external_link && (
                    <a
                      className="button--primary button--with-shadow"
                      href={featured_resource_external_link}
                    >
                      {getCtaLinkText(featured_resource.tags[1].slug)}
                    </a>
                  )}
                  {!featured_resource_external_link && (
                    <Link
                      className="button--primary button--with-shadow"
                      to={`/resources/${featured_resource.slug}/`}
                    >
                      {getCtaLinkText(featured_resource.tags[1].slug)}
                    </Link>
                  )}
                </div>

                {resourcesPage.featureImageSharp && (
                  <GatsbyImage
                    image={getImage(resourcesPage.featureImageSharp)}
                    alt={featured_resource.title}
                    className="feature-image"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <SecondaryNav
          items={[
            {
              label: "All Resources",
              url: "/resources/",
              type: "internal",
            },
            {
              label: "White Papers",
              url: "/resources/white-paper/",
              type: "internal",
            },
            {
              label: "Datasheets",
              url: "/resources/datasheet/",
              type: "internal",
            },
            {
              label: "Case Studies",
              url: "/resources/case-study/",
              type: "internal",
            },
            {
              label: "Webinars",
              url: "/resources/webinar/",
              type: "internal",
            },
            {
              label: "Online Meetups",
              url: "/resources/online-meetup/",
              type: "internal",
            },
            {
              label: "Videos",
              url: "/resources/video/",
              type: "internal",
            },
            {
              label: "Reports",
              url: "/resources/report/",
              type: "internal",
            },
          ]}
          currentPath={location.pathname}
        />

        {/* Posts */}
        <Box py="60px">
          <div className="wrapper-1200">
            <Grid
              gridTemplateColumns={[
                "1fr",
                "repeat(2, 1fr)",
                null,
                "repeat(3, 1fr)",
              ]}
              gridGap={["40px", "40px 30px", null, "60px 30px"]}
              justifyItems="center"
            >
              {renderedPosts.map(({ node }) => (
                <ResourceCard data={node} key={node.id} />
              ))}
            </Grid>
          </div>
        </Box>

        {/* Load More */}
        {!loading && page !== post_chunks.length - 1 && posts.length !== 0 && (
          <Box className="load-more-posts" mt="20px">
            <button
              onClick={() => loadPosts()}
              type="button"
              className="button--primary button--extra-padding button--with-shadow"
            >
              Load more
            </button>
          </Box>
        )}

        {/* Empty posts message */}
        {posts.length == 0 && (
          <div className="empty-posts-message">No posts available</div>
        )}
      </div>
    </Layout>
  )
}

export default ResourcesPage

const getCtaLinkText = (tag_slug: string) => {
  let link_text = "Learn More"
  switch (tag_slug) {
    case "white-paper":
      link_text = "Read White Paper"
      break
    case "datasheet":
      link_text = "Request Datasheet"
      break
    case "case-study":
      link_text = "Read Case Study"
      break
    case "webinar":
      link_text = "Request Access"
      break
    case "video":
      link_text = "Watch Video"
      break
    default:
      link_text = "Learn More"
  }
  return link_text
}

export const query = graphql`
  query resourcesQueries($slug: String!) {
    featured_resource: ghostPost(
      primary_tag: { slug: { eq: "resources" } }
      featured: { eq: true }
    ) {
      ...GhostPostCardFields
    }

    resources_page: ghostPage(slug: { eq: "resources" }) {
      featureImageSharp {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 80, placeholder: NONE)
        }
      }
    }

    posts: allGhostPost(
      filter: {
        primary_tag: { slug: { eq: "resources" } }
        tags: { elemMatch: { slug: { eq: $slug } } }
      }
      sort: { order: DESC, fields: [published_at] }
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }

    currentPage: ghostPage(slug: { eq: "resources" }) {
      ...PageMetaFields
    }
  }
`
